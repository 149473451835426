import React  from 'react';
import Navigation from '../Navigation/Navigation'
import '../../app.scss';
import irene from '../../assets/irene.jpg';
import cv from '../../assets/Irene_Prins_CV.pdf';
import background from '../../assets/watercolor.jpg';

export default function Header() {
    return (
        <>
            <div className="header" style={{ backgroundImage:`url(${background})`}}>
                <Navigation />
                <div className="container">
                    <div className="introduction">
                        <div className="introduction-text">
                            <h1 className="introduction-text__title">Irene Prins</h1>
                            <h2 className="introduction-text__subtitle">Webdeveloper</h2>
                            <a href={process.env.PUBLIC_URL+cv} className="button" download target="_blank" rel="noopener noreferrer">Download cv</a>
                        </div>
                    </div>
                </div>
                <div className="container" id="about">
                    <div className="about">
                        <div className="about__image">
                            <img src={irene} alt="Design" className="about__irene" />
                        </div>
                        <div className="about__content">
                            <h1 className="about__title">Over mij</h1>
                            <p className="about__text">Ik ben Irene Prins. Ik ben 21 jaar uit en ik kom uit het Westland. Ik zit in het laatste jaar van mijn opleiding Creative Media and Game Technologies aan de Hogeschool Rotterdam. Na mijn afstuderen kan ik mijzelf officieel een creatief technoloog noemen! </p>
                            <p>Ik ben een creatief en oplossingsgericht persoon. Ik kan goed zelfstandig werken, maar ik vind het ook leuk om onder de mensen te zijn. Ik ben nieuwsgierig en leergierig. Ik heb mij deze jaren ontwikkeld als full stack webdeveloper. Ik vind het erg leuk om te werken aan de frontend met talen zoals Javascript en Typescript. Ook vind ik het interessant om projecten te doen met React. Op dit moment ben ik bezig met de minor Data Science. Dit vind ik naast fullstack developen ook erg interessant.</p>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}